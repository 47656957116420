import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger";
import Tooltip from "@rio-cloud/rio-uikit/Tooltip";
import moment from "moment";
import { useIntl } from "react-intl";
import { ResponsiveContainer, Bar, BarChart, XAxis, YAxis, CartesianGrid, Cell, LabelList, Legend } from "recharts";

const CustomReportHistogramChart = ({ chartData }: any) => {
	const yValue = chartData?.complementaryData;
	const isGraphWide = chartData?.chart.length > 15;
	const isSmallGraph = chartData.columnSize <= 5;
	const limit = chartData?.chart.length > 5 ? 15 : 25;
	const specificChart = ["RPM"];

	function transformObject(input: any): any[] {
		return input.chart.map((entry: any) => {
			const totalEntryIndex = entry.axisY.findIndex((y: any) => y.name === "Total");
			const total = totalEntryIndex !== -1 ? entry.axisY[totalEntryIndex].value : null;
			const filteredAxisY = totalEntryIndex !== -1 ? entry.axisY.filter((_: any, index: any) => index !== totalEntryIndex) : entry.axisY;

			return {
				axisX: entry.axisX,
				axisY: filteredAxisY,
				total: total,
			};
		});
	}

	function transformTime(input: any): any[] {
		return input.map((entry: any) => {
			const totalMs = entry.axisY.reduce((acc: number, y: any) => {
				const duration = moment.duration(y.value);
				return acc + duration.asMilliseconds();
			}, 0);

			return {
				axisX: entry.axisX,
				axisY: entry.axisY.map((y: any) => ({
					name: y.name,
					value: y.value,
					totalMs: y.value === "00:00" ? 0 : y.value === "Total" ? totalMs : moment.duration(y.value).asMilliseconds(),
				})),
				total: entry.total,
				totalMs: totalMs,
			};
		});
	}

	const formattedChart = transformObject(chartData);
	const correctChart = transformTime(formattedChart);
	const intl = useIntl();

	const CustomXAxis = ({ x, y, payload }: any) => {
		const isOverflow = payload.value.length > limit;

		return (
			<OverlayTrigger
				key={payload.value}
				placement={"bottom-end"}
				delay={{
					show: isOverflow || (isSmallGraph && isGraphWide) ? 0 : 99999,
					hide: 0,
				}}
				overlay={
					<Tooltip id="tooltip" allowOnTouch>
						{intl.formatMessage({ id: "customReport.graph." + yValue + "." + payload.value })}
					</Tooltip>
				}
			>
				<g
					transform={`translate(${x - ((isOverflow || isGraphWide) && chartData.complementaryData.includes(specificChart) ? 40 : 0)},${
						y + ((isOverflow || isGraphWide) && chartData.complementaryData.includes(specificChart) ? 10 : 0)
					})`}
				>
					<line x1={0} x2={0} y1={-10} y2={-10} stroke="#666" strokeWidth={1} />
					<text
						dy={35}
						textAnchor="middle"
						fill="#666"
						width={"100%"}
						transform={`rotate(${(isOverflow || isGraphWide) && chartData.complementaryData.includes(specificChart) ? (isSmallGraph ? -90 : -35) : 0})`}
					>
						{intl.formatMessage({ id: "customReport.graph." + yValue + "." + payload.value })}
					</text>
				</g>
			</OverlayTrigger>
		);
	};

	return (
		<>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart data={correctChart}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="axisX" allowDataOverflow />
					<YAxis
						tickFormatter={(value) => {
							if (value == 0) {
								return "00:00";
							} else {
								function msToHHMM(milliseconds: number) {
									let totalSeconds = Math.floor(milliseconds / 1000);
									let hours = Math.floor(totalSeconds / 3600);
									let minutes = Math.floor((totalSeconds % 3600) / 60);
									let formattedHours = String(hours).padStart(2, "0");
									let formattedMinutes = String(minutes).padStart(2, "0");
									return `${formattedHours}:${formattedMinutes}`;
								}
								return msToHHMM(value);
							}
						}}
						label={{
							angle: -90,
							position: "insideLeft",
							offset: -10,
							style: { textAnchor: "middle", padding: 20 },
						}}
					/>
					<Legend height={0} formatter={(value) => ""} iconSize={0} />
					<Bar dataKey="totalMs" isAnimationActive={false} maxBarSize={60}>
						{correctChart.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={"#30b4c0"} />
						))}
						<LabelList dataKey={"total"} position="top" />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

export default CustomReportHistogramChart;
