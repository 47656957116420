import { Spinner } from "@rio-cloud/rio-uikit";
import Button from "@rio-cloud/rio-uikit/Button";
import { subDays } from "date-fns";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../configuration/setup/hooks";
import { useGetChartsMutation, useGetListChartsIdsMutation } from "../../services/customReportApi";
import { getAppData } from "../../stores/app/appSlice";
import { getChartsFetched, setOpenCustomReportSidesheet } from "../../stores/customReport/customReportPageSlice";
import ChartGrid from "./grid/ChartGrid";

const CustomReportPage = () => {
	const [getCharts, { data, isLoading }] = useGetChartsMutation();
	const [getListCharts] = useGetListChartsIdsMutation();
	const { assets, tags, dateTime } = useAppSelector(getAppData);
	const listCharts = useAppSelector(getChartsFetched);
	const dispatch = useAppDispatch();

	const payload = {
		assetIds: assets,
		tagIds: tags,
		startAt: subDays(dateTime.start, 7),
		endAt: dateTime.end,
		zone: "UTC-3",
		infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
		search: "",
		page: 0,
		pageSize: 50,
	};

	const handleTesat = async () => {
		const testa = await getListCharts(payload);
	};

	useEffect(() => {
		handleTesat();
	}, []);

	useEffect(() => {
		getCharts(payload);
	}, [assets, tags]);

	return (
		<div className="display-flex gap-25 flex-column width-100pct height-100pct">
			<Button className="max-width-150 min-height-35" onClick={() => dispatch(setOpenCustomReportSidesheet({ open: true, chart: null }))}>
				Criar gráfico
			</Button>
			{isLoading ? (
				<Spinner />
			) : listCharts.length == 0 ? (
				<div>Crie um gráfico</div>
			) : (
				<ChartGrid page="customReport" cols={{ lg: 12, md: 14, sm: 8, xs: 6, xxs: 4 }} listCharts={listCharts} />
			)}
		</div>
	);
};

export default CustomReportPage;
