/* eslint-disable camelcase, no-console */
import {
	type UserProfile as Profile,
	User,
	UserManager,
	type UserManagerSettings,
	WebStorageStateStore,
	InMemoryWebStorage,
} from "oidc-client-ts";
import join from "lodash/fp/join";

import { mapUserProfile } from "./userProfile";
import { config } from "../../config";
import type { OAuthConfig } from "..";
import type { AccessToken } from "../tokenHandling/tokenSlice";
import type { UserProfile } from "./loginSlice";

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.signinSilent().catch((error: Error) => {
		if (error.message === "login_required") {
			oauthConfig.onSessionExpired();
		} else {
			setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
		}
	});
};

export type SessionRenewedResult = {
	accessToken: AccessToken;
	idToken: Profile;
	profile: UserProfile;
	locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
	accessToken: result.access_token,
	idToken: result.profile,
	locale: result.profile?.locale ?? "en-GB",
	profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
	const redirectUri = config.login.redirectUri;
	const silentRedirectUri = config.login.silentRedirectUri;

	const settings: UserManagerSettings = {
		authority: `${config.login.authority}`,
		client_id: `${config.login.clientId}`,
		loadUserInfo: false,
		redirect_uri: `${redirectUri}`,
		response_type: "code",
		scope: join(" ", config.login.oauthScope),
		silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
		includeIdTokenInSilentRenew: false,
		automaticSilentRenew: true,
		monitorSession: true,
		staleStateAgeInSeconds: 600,
		userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
		filterProtocolClaims: false,
	};

	return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.events.addUserLoaded((user) => {
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
	});

	userManager.events.addUserUnloaded(() => {
		oauthConfig.onSessionExpired();
	});

	userManager.events.addSilentRenewError(() => {
		retrySigninSilent(oauthConfig, userManager);
	});

	userManager.events.addUserSignedOut(() => {
		oauthConfig.onSessionExpired();
	});

	return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
	const signinSilent = () => {
		const userSettings = {
			// manual token
			access_token:
				"eyJraWQiOiJjMDNmNDMwMy05OTk4LTRkYmItYjgxNC03OGI0MmIwNmE0NDkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwcm9kLXJpby11c2Vyczo3ZGYzNDY0MS03NzNmLTRlYjUtYmM1ZS1iMTE1MGE5OGIwNjgiLCJhenAiOiI3MjY1MDUwMC1hMmYxLTRiNTEtODVlOS0xODM1OTRlNGM1MjciLCJzY29wZSI6ImFzc2V0LWFkbWluaXN0cmF0aW9uLnJlYWQgcGhvbmUgb3BlbmlkIGRyaXZlcnMucmVhZCBwcm9maWxlIHRhZ3MucmVhZCBlbWFpbCIsImlzcyI6Imh0dHBzOi8vYXV0aC5pYW0ucmlvLmNsb3VkIiwiZXhwIjoxNzI3MjkzOTIyLCJpYXQiOjE3MjcyOTAzMjIsImp0aSI6IjA2YTM1ZDljLTAyNzUtNGRjZC04MDNlLTU1ZjY2ZmRjY2RjYiIsImNsaWVudF9pZCI6IjcyNjUwNTAwLWEyZjEtNGI1MS04NWU5LTE4MzU5NGU0YzUyNyIsImFjY291bnQiOiI2Mjc5YTg0Mi0wOGJiLTQwZmMtODA1Yi0wN2M1MTgyNDE0YjciLCJ0ZW5hbnQiOiJyaW8tYnJhemlsLnByb2QifQ.hymExWMa3SIJpQcDT0u2SC6s8M24YBxhNs2ZZXxUwbuFCWYcXXGT4w3EjWD65-sGxrt78Jl9Xdk2ssKcwj_GvlePY5-TNVcD_EsoX9R_85n2cNg4iAMdjJy_tZbRfr9zHM3Fj1iBzQc4O0nGSZisSvz9LSsRlC6_bDD80NXSfgGoU_nZxzXxjBhThaIlhGmx6J2fcTBPeuUeZE5b6d47StfWVHavAz_CyJpa7Mkk9qfI9ufZO2Q8pvKLrww4UgPm5CV5-oOPdNvCqazAWU2iCrsffBE_ac6aNv9v-qpx_i9-eH_I6_9HjnxR_pZhnucEr7-aAW7nXY_vD8CA711MxqQaumVzW2pKvk4Bmw7asJARSJlWFCQcniXHu5h8nwySjA36SqHt4Go_vukmaq-Ku1vdStgnZ_mENs44xYezPRdF8eG6VD2EeuqGViqQI-bJdYSQ3qmqtN9JLyky6RjTJJdo-CWSMmoVYBsnE0kz5mleo1SrB2lZzivg6lfmrEUnfoXNlb40hhXoyhqAI636w-RhMK2QVeut8Cef5E7FKSxeNCYH9RRZ9cxK8vaEsBrXxHwEH_vvZ28qfumHri-vIn-UzNUyY-6LMNcFpzaZMyooXqCQCATHYc7coHrycZYsMKfiJOzRE-C8xRaSTnm9B0iy4ggDNWQzcPCo24842js",
			profile: {
				iss: "Issuer Identifier",
				aud: "Audience(s): client_id",
				exp: 101293012,
				iat: 5,
				account: "6279a842-08bb-40fc-805b-07c5182414b7", // evento e-delivery
				azp: "test-client",
				email: "test@example.com",
				family_name: "Client",
				given_name: "Test",
				name: "Test Client",
				sub: "prod-rio-users:mock-user",
				locale: config.login.mockLocale,
				tenant: config.login.mockTenant,
			},
			id_token: "id_token",
			session_state: "session_state",
			refresh_token: "refresh_token",
			token_type: "token_type",
			scope: "scope",
			expires_at: 101293012,
			state: "state",
		};

		const user = new User(userSettings);
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
		return Promise.resolve(user);
	};

	const clearStaleState = () => {
		console.info("[configuration/login/oidc-session] Stale state cleared");
		return Promise.resolve();
	};

	return { signinSilent, clearStaleState } as UserManager;
};
