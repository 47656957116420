import Spinner from "@rio-cloud/rio-uikit/Spinner";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const CustomReportLineChart = ({ chartData, intl, statusValue }: any) => {
	const hasNoData = chartData?.length === 0 || !chartData;

	const chartInfoData = chartData?.chart;

	// Transform the data to fit the structure expected by Recharts
	const formattedData = chartInfoData?.map((dataPoint: any) => {
		const entry: any = { week: dataPoint.axisX }; // Use axisX (week number) as X-axis
		dataPoint.axisY.forEach((item: any) => {
			entry[item.name] = Number(item.value); // Use the name as key and value as Y-axis data
		});
		return entry;
	});

	// Extract all the unique 'name' fields from axisY to create lines dynamically
	const lineKeys = chartInfoData?.[0]?.axisY?.map((y: any) => y.name) || [];

	return (
		<>
			<ResponsiveContainer width={"99%"} height={"100%"}>
				{hasNoData || statusValue === "loading" ? (
					<Spinner />
				) : (
					<LineChart
						data={formattedData}
						margin={{
							right: 20,
							left: 20,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="week" tickMargin={20} />
						<YAxis />
						<Tooltip />
						<Legend
							iconType={"square"}
							wrapperStyle={{ padding: 20, bottom: -20, maxHeight: "120px", overflowY: "auto" }}
							formatter={(value) => <span className="text-color-black">{value}</span>}
						/>
						{lineKeys.map((key: string) => (
							<Line
								key={key}
								type="monotone"
								connectNulls={true}
								isAnimationActive={false}
								dataKey={key}
								name={key}
								stroke="#8884d8"
								activeDot={{ r: 8 }}
							/>
						))}
					</LineChart>
				)}
			</ResponsiveContainer>
		</>
	);
};

export default CustomReportLineChart;
