import { ResponsiveContainer, Bar, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, LabelList } from "recharts";
import { chartColors } from "../../../constants/charts";

const CustomReportBarChart = ({ chartData }: any) => {
	const data = chartData.chart.map((item: { axisX: string; axisY: { name: string; value: string }[] }) => ({
		axisX: item.axisX,
		...item.axisY.reduce((acc: { [x: string]: string }, curr: { name: string | number; value: string }) => {
			acc[curr.name] = curr.value;
			return acc;
		}, {}),
	}));

	if (data.length == 0) return <div>Sem dados</div>;

	const uniqueDataKeys = Array.from(
		new Set(
			data?.reduce((keys: any, item: any) => {
				return keys.concat(Object.keys(item).filter((key) => key !== "axisX"));
			}, []),
		),
	);

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart data={data}>
				<XAxis dataKey="axisX" />
				<YAxis />
				<Tooltip />
				<CartesianGrid strokeDasharray="3 3" />
				{uniqueDataKeys?.map((key: any, subIndex: any) => {
					return (
						<>
							<Bar
								key={key}
								dataKey={key}
								style={{ minHeight: 50 }}
								stackId={key}
								fill={chartColors[subIndex % chartColors.length]}
								name={key}
								isAnimationActive={false}
								maxBarSize={60}
							>
								<LabelList dataKey={key} position="top" />
							</Bar>
						</>
					);
				})}{" "}
			</BarChart>
		</ResponsiveContainer>
	);
};

export default CustomReportBarChart;
