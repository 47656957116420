import { Button, DatePicker } from "@rio-cloud/rio-uikit";
import { isEqual, subHours } from "date-fns";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { setOpenCustomReportSidesheet } from "../../../stores/customReport/customReportPageSlice";
import { useAppDispatch } from "../../../configuration/setup/hooks";
import { isValidDateForToSelection, isValidDateStart } from "../../../components/common/date/helper";

type ChartHeaderProps = {
	chartFiltered: any;
	onDelete: (id: string) => void;
	onDateChange: (id: string, startDate: moment.Moment, endDate: moment.Moment) => void;
};

const ChartHeader: React.FC<ChartHeaderProps> = ({ chartFiltered, onDelete, onDateChange }) => {
	const intl = useIntl();
	const chartStartDate = subHours(new Date(chartFiltered.startAt), 3);
	const chartEndDate = subHours(new Date(chartFiltered.endAt), 3);
	const initialMount = useRef(true);

	const [startDay, setStartDay] = useState(moment(chartStartDate));
	const [endDay, setEndDay] = useState(moment(chartEndDate));
	const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false);
	const endDatePickerInput = useRef<any>(null);

	const chartId = chartFiltered.id;
	const chartName = chartFiltered.name;

	const isDateDifferent =
		!isEqual(chartStartDate, moment(startDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ")) ||
		!isEqual(chartEndDate, moment(endDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"));

	useEffect(() => {
		if (!initialMount.current && endDay && !isEndDatePickerOpen && isDateDifferent) {
			onDateChange(chartId, startDay, endDay);
		} else {
			initialMount.current = false;
		}
	}, [endDay, isEndDatePickerOpen]);

	const dispatch = useAppDispatch();

	return (
		<div className="display-flex flex-column justify-between items-center">
			<div className="display-flex items-center">
				<Button onClick={() => onDelete(chartId)}>Delete</Button>
				<Button onClick={() => dispatch(setOpenCustomReportSidesheet({ open: true, chart: chartFiltered }))}>Editar</Button>
				<div className="display-flex gap-5 width-350">
					<DatePicker
						value={startDay}
						closeOnClickOutside={true}
						locale={intl.locale}
						className="width-100pct z-index-max"
						onChange={(date) => setStartDay(moment(date))}
						isValidDate={(date) => isValidDateStart(date)}
						onOpen={() => setEndDatePickerOpen(false)}
						onClose={() => {
							setTimeout(() => {
								endDatePickerInput.current.focus();
							}, 100);
						}}
					/>
					<DatePicker
						value={endDay}
						closeOnSelect={true}
						closeOnClickOutside={true}
						locale={intl.locale}
						className="width-100pct z-index-max"
						inputProps={{ ref: endDatePickerInput }}
						isValidDate={(date) => isValidDateForToSelection(date, startDay)}
						onChange={(date) => setEndDay(moment(date))}
						onOpen={() => setEndDatePickerOpen(true)}
						onClose={() => setEndDatePickerOpen(false)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ChartHeader;
