import React, { useEffect, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { getUserAccount } from "../../../configuration/login/loginSlice";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { getChartsFetched } from "../../../stores/customReport/customReportPageSlice";
import ChartItem from "./ChartItem";
import { shallowEqual, useSelector, useStore } from "react-redux";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

type ReactGridLayoutProps = {
	cols: { [breakpoint: string]: number };
	classname?: string;
	useCSSTransforms?: boolean;
	isBounded?: boolean;
	savedLayouts?: { [breakpoint: string]: Layout[] };
	defaultPanelHeight?: number;
	defaultPanelWidth?: number;
	page: string;
	listCharts?: any;
};

type GridStateProps = {
	currentBreakpoint: string;
	currentCols: number;
	layouts: { [breakpoint: string]: Layout[] };
	currentLayout: Layout[];
};

const ChartGrid = ({ defaultPanelHeight, listCharts, defaultPanelWidth, page, ...props }: ReactGridLayoutProps) => {
	const accountId = useAppSelector(getUserAccount);

	const [state, setState] = useState<GridStateProps>({
		currentBreakpoint: "lg",
		currentCols: props.cols.lg || 12,
		layouts: {},
		currentLayout: [],
	});

	const getSavedLayouts = () => {
		if (typeof window !== "undefined" && window.localStorage) {
			const saved = window.localStorage.getItem(`${page}-${accountId}-grid-layout-config`);
			if (saved) {
				return JSON.parse(saved);
			}
		}
		return {};
	};

	useEffect(() => {
		const { currentBreakpoint } = state;
		const initialLayouts = getSavedLayouts();
		const initialLayout = initialLayouts[currentBreakpoint] || [];

		setState((prevState) => ({
			...prevState,
			layouts: initialLayouts,
			currentLayout: initialLayout,
		}));
	}, [state.currentBreakpoint, accountId, page]);

	useEffect(() => {
		const columns = state.currentCols;
		const newLayout: Layout[] = [];
		let xOffset = 0;
		let yOffset = 0;

		listCharts.forEach((chart: any, index: number) => {
			const width = chart.columnSize || defaultPanelWidth || 3;
			const height = defaultPanelHeight || 3;
			const idChart = chart.id;

			if (xOffset + width > columns && index > 0) {
				xOffset = 0;
				yOffset += height;
			}

			newLayout.push({
				i: String(idChart),
				x: xOffset,
				y: yOffset,
				w: width,
				h: height,
				maxH: 3,
				minW: 4,
				minH: 3,
			});

			xOffset += Number(width);
		});

		setState((prevState) => ({
			...prevState,
			layouts: {
				...prevState.layouts,
				[prevState.currentBreakpoint]: newLayout,
			},
			currentLayout: newLayout,
		}));
	}, [listCharts, state.currentCols, defaultPanelHeight, defaultPanelWidth]);

	return (
		<ResponsiveReactGridLayout
			useCSSTransforms={true}
			compactType={"horizontal"}
			isDraggable={false}
			containerPadding={[1, 1]}
			onLayoutChange={(newLayout) => {
				setState((prevState) => ({
					...prevState,
					layouts: {
						...prevState.layouts,
						[prevState.currentBreakpoint]: newLayout,
					},
				}));
			}}
		>
			{state.currentLayout.map((layoutItem) => {
				const chart = listCharts.find((chart: any) => chart.id === layoutItem.i);
				if (!chart) return null;

				return (
					<div
						key={layoutItem.i}
						data-grid={layoutItem}
						className="bg-white rounded padding-20 display-flex flex-column justify-between gap-20"
					>
						<ChartItem chart={chart} />
					</div>
				);
			})}
		</ResponsiveReactGridLayout>
	);
};

export default ChartGrid;
