import { Spinner } from "@rio-cloud/rio-uikit";
import { useState, useEffect } from "react";
import { useDeleteSingleChartMutation, useGetSingleChartByIdMutation } from "../../../services/customReportApi";
import ChartDecider from "../chart/ChartDecider";
import ChartHeader from "../chart/ChartHeader";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { deleteChart, getUpdatedChart } from "../../../stores/customReport/customReportPageSlice";
import { getAppData } from "../../../stores/app/appSlice";

type ChartItemProps = {
	chart: any;
};

const ChartItem = ({ chart }: ChartItemProps) => {
	const [currentChart, setCurrentChart] = useState(chart);
	const [loadingCharts, setLoadingCharts] = useState<boolean>(false);
	const [deleteSingleChart] = useDeleteSingleChartMutation();
	const [getSingleChartById] = useGetSingleChartByIdMutation();
	const { assets, tags } = useAppSelector(getAppData);
	const updatedChart = useAppSelector(getUpdatedChart);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (updatedChart && updatedChart.id === currentChart.id) {
			setCurrentChart(updatedChart);
		}
	}, [updatedChart, currentChart.id]);

	const handleDeleteChart = async () => {
		try {
			const chartToDelete = {
				...currentChart,
				startAt: `${currentChart.startAt}-03:00`,
				endAt: `${currentChart.endAt}-03:00`,
			};

			await deleteSingleChart(chartToDelete);
			dispatch(deleteChart(chartToDelete));
		} catch (error) {
			console.error("Error deleting chart:", error);
		}
	};

	const handleDateChange = async (chartId: string, startDay: any, endDate: any) => {
		setLoadingCharts(true);

		try {
			const updatedChart = await getSingleChartById({
				customId: chartId,
				requestPayload: {
					assetIds: assets,
					tagIds: tags,
					startAt: startDay.format("YYYY-MM-DDTHH:mm:ssZ"),
					endAt: endDate.format("YYYY-MM-DDTHH:mm:ssZ"),
					zone: "UTC-3",
					infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
					search: "",
					page: 0,
					pageSize: 50,
				},
			}).unwrap();

			setCurrentChart(updatedChart);
		} catch (error) {
			console.error("Error updating chart:", error);
		} finally {
			setLoadingCharts(false);
		}
	};

	useEffect(() => {
		setCurrentChart(chart);
	}, [chart]);

	if (!currentChart) return <Spinner />;

	return (
		<>
			<div className="height-5pct display-flex flex-column justify-content-center" style={{ zIndex: 2 }}>
				<ChartHeader onDateChange={handleDateChange} chartFiltered={currentChart} onDelete={handleDeleteChart} />
				<span className="text-size-h4 text-center">{currentChart.chartName}</span>
			</div>
			<div className="height-90pct padding-y-5">{loadingCharts ? <Spinner /> : <ChartDecider chart={currentChart} />}</div>
		</>
	);
};

export default ChartItem;
