import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { customReportApi } from "../../services/customReportApi";

interface CustomReportPageState {
	customReportSidesheet: {
		open: boolean;
		chart: any | {};
	};
	chartsFetched: any[];
	updatedChart: any | {};
}

const initialState: CustomReportPageState = {
	customReportSidesheet: {
		open: false,
		chart: {},
	},
	chartsFetched: [],
	updatedChart: {},
};

const customReportPageSlice = createSlice({
	name: "customReportPage",
	initialState,
	reducers: {
		setOpenCustomReportSidesheet: (state, action: PayloadAction<{ open: boolean; chart: any | {} }>) => {
			state.customReportSidesheet = action.payload;
		},
		deleteChart: (state, action: PayloadAction<any>) => {
			state.chartsFetched = state.chartsFetched.filter((chart) => chart.id !== action.payload.id);
		},
		addChart: (state, action: PayloadAction<any>) => {
			state.chartsFetched = [...state.chartsFetched, action.payload]; // Create a new array
		},
		clearCharts: (state) => {
			state.chartsFetched = [];
		},
		updateChart: (state, action: PayloadAction<{ id: string; updates: Partial<any> }>) => {
			const { id, updates } = action.payload;
			state.chartsFetched = state.chartsFetched.map((chart) => (chart.id === id ? { ...chart, ...updates } : chart));
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(customReportApi.endpoints.getCharts.matchFulfilled, (state, action: PayloadAction<any[]>) => {
			state.chartsFetched = [...action.payload].sort((a, b) => a.viewOrder - b.viewOrder);
		});
	},
});

export const { setOpenCustomReportSidesheet, deleteChart, addChart, clearCharts, updateChart } = customReportPageSlice.actions;

export const getChartsFetched = (state: RootState) => state.pageCustomReport.chartsFetched;
export const getCustomReportSidesheet = (state: RootState) => state.pageCustomReport.customReportSidesheet;
export const getUpdatedChart = (state: RootState) => state.pageCustomReport.updatedChart;

export default customReportPageSlice.reducer;
