import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const CustomReportPieChart = ({ chartData, intl, statusValue }: any) => {
	const hasNoData = !chartData.chart?.axisY || chartData.chart?.axisY?.length === 0;

	const formattedData = chartData.chart[0]?.axisY?.map((item: any) => ({
		name: item.name,
		value: parseFloat(item.value), // Ensure value is a number
	}));

	const chartColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"]; // Define some colors

	return (
		<ResponsiveContainer width={"99%"} height={300}>
			<PieChart>
				<Pie
					data={formattedData}
					dataKey="value"
					nameKey="name"
					cx="50%"
					isAnimationActive={false}
					cy="50%"
					outerRadius={80}
					fill="#8884d8"
					label={({ name, value }: any) => `${name}: ${value}`} // Display the name and value
				>
					{formattedData?.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />)}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default CustomReportPieChart;
